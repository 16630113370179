<template>
    <v-container>
        <v-form @submit.prevent="Update()">
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="ค้นหาข้อมูล"
                                  placeholder="รหัส"
                                  outlined
                                  required
                                  dense
                                  v-model="User.SettingAutenPassSelect"
                                  type="password"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="เพิ่มข้อมูล"
                                  placeholder="รหัส"
                                  outlined
                                  required
                                  dense
                                  v-model="User.SettingAutenPassInsert"
                                  type="password"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="ลบข้อมูล"
                                  placeholder="รหัส"
                                  outlined
                                  required
                                  dense
                                  v-model="User.SettingAutenPassDelete"
                                  type="password"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="3">
                    <v-text-field label="แก้ใข"
                                  placeholder="แก้ใข"
                                  outlined
                                  required
                                  dense
                                  v-model="User.SettingAutenPassUpdate"
                                  type="password"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="12" md="6" lg="1">
                    <v-btn block type="submit">บันทึก</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="1">
                    <v-btn block @click="$router.push('/Setting')">กลับ</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
    import axios from "axios";

    export default {
        data() {
            return {
                User: {
                    UserId: "",
                    SettingAutenPassInsert: "",
                    SettingAutenPassUpdate: "",
                    SettingAutenPassSelect: "",
                    SettingAutenPassDelete: ""
                }
            };
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    if (this.$route.query.UserId != null) {
                        this.User.UserId = this.$route.query.UserId;
                        this.Insert();
                    }
                } else {
                    this.$router.push("/Member");
                }
            });
        },
        methods: {
            SelectId() {
                axios.get("/Api/V1/Select/SelectId/Setting?UserId=" + this.User.UserId).then(res => {
                    if (res.status == 200) {
                        this.User = res.data;
                    }
                });
            },
            Insert() {
                axios.post("/Api/V1/Insert/Insert/Setting", {
                    UserId: this.User.UserId
                }).then(res => {
                    if (res.status == 200) {
                        this.SelectId()
                    }
                });
            },
            Update() {
                axios.put("/Api/V1/Update/Update/Setting", this.User).then(res => {
                    if (res.status == 200) {
                        this.$router.push('/Setting')
                    }
                });
            }
        }
    };
</script>
